<template>
    <b-overlay :show="loading">
        <h3 class="mb-1">Pembelian Belum Lunas <span v-if="pembelians.length > 0">({{ pembelians.length }})</span></h3>
        <b-card>
            <b-row class="mb-2">
      
                <b-col v-if="$route.query.total" cols="12">
                    <h2 class="text-danger">
                      <strong>Total Utang: Rp {{ formatRupiah(totalUtang) }}</strong>
                    </h2>
                  </b-col>
                  <!-- Header -->
                <b-col cols="12" class="mb-2">
                    <b-button variant="primary" v-if="allowCreate()" @click.prevent="add">Add</b-button>
                </b-col>
                <b-col sm="12" md="6">
                    <b-form-group class="mb-0">
                        <label class="d-inline-block text-sm-left mr-50">Per page</label>
                        <b-form-select
                            id="perPageSelect"
                            v-model="perPage"
                            size="sm"
                            :options="pageOptions"
                            class="w-50"
                        />
                    </b-form-group>
                </b-col>
                <b-col sm="12" md="6">
                    <b-form-group
                    :label="$t('Filter')"
                    label-cols-sm="3"
                    label-align-sm="right"
                    label-size="sm"
                    label-for="filterInput"
                    class="mb-0"
                    >
                        <b-input-group size="sm">
                            <b-form-input
                            id="filterInput"
                            v-model="filter"
                            type="search"
                            
                            />
                            <b-input-group-append>
                            <b-button :disabled="!filter" @click="filter = ''">{{$t('Clear')}}</b-button>
                            </b-input-group-append>
                        </b-input-group>
                    </b-form-group>
                </b-col>
                <!-- / -->
      
            </b-row>
            <!-- Body -->
            <b-card>
                <b-row>
                    <b-col cols="12">
                        <b-table small :per-page="perPage" :current-page="currentPage" responsive :fields="fields" :items="pembelians">
                            <template #cell(order)="{index}">
                                {{++index}}
                            </template>
                            <template #cell(informasi)="{item}">
                                Rp {{ item.informasi ? formatRupiah(item.informasi.total_pembelian) : '' }}
                            </template>
                            <template #cell(tanggal)="{ item }">
                              {{humanDate(item.tanggal)}}
                              </template>
                            <template #cell(status)="{item}">
                              <b-badge v-if="item.informasi && item.informasi.hutang == 0" variant="light-success">
                                  Lunas
                              </b-badge>
                              <b-badge v-else variant="light-danger">Belum Lunas</b-badge>
                            </template>
                            <template #cell(izin)="{item}">
                                <div class="d-flex justify-content-center">
                                    <b-badge variant="light-success" v-if="item.izin == 1">Disetujui</b-badge>
                                    <b-badge variant="light-danger" v-else>Belum Disetujui</b-badge>
                                </div>
                            </template>
                            <template #cell(actions)="{item}">
                                <div class="flex align-items-center justify-center">
                                    <b-button
                                        size="sm"
                                        @click="detail(item)"
                                        class="mr-1"
                                        variant="outline-info"
                                        >
                                        <feather-icon icon="EyeIcon" />
                                    </b-button>
                                </div>
                            </template>
                        </b-table>
                    </b-col>
                    <b-col cols="12">
                        <b-pagination
                            v-model="currentPage"
                            :total-rows="totalRows"
                            :per-page="perPage"
                            align="center"
                            size="sm"
                            class="my-0"
                        />
                    </b-col>
                </b-row>
            </b-card>
            <!-- / -->
        </b-card>
    </b-overlay>
  </template>
  <script>
  import {BOverlay, BRow, BBadge, BCol, BCard, BPagination, BTable, BFormGroup, BInputGroup, BInputGroupAppend, BButton, BFormInput, BFormSelect} from 'bootstrap-vue'
  export default {
    components: {
        BOverlay,
        BRow, BCol, BBadge, BFormGroup, BCard, BPagination, BTable, BInputGroup, BInputGroupAppend, BButton, BFormInput, BFormSelect
    },
    data: () => ({
        loading: false,
        perPage: 10,
        totalRows: 0,
        currentPage: 1,
        pageOptions: [10, 30, 50],
        filter: null,
        fields: [
            {key: 'order', label: 'No'},
            {key: 'no', label: 'No. Pembelian'},
            {key: 'tanggal', label: 'Tanggal'},
            {key: 'instansi', label: 'Supplier'},
            {key: 'status', label: 'Pembayaran'},
            {key: 'izin', label: 'Status'},
            {key: 'actions', label: '#'}
        ],
        pembelians: [],
        hasPermissionApprove: false
    }),
    methods: {
        edit(item) {
            this.$router.push(`/pembelian/edit/${item.id}`)
        },
        remove(item)  {
            this.$swal({
                title: 'Anda yakin?',
                text: `Data pembelian ini akan dihapus`,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Ya',
                cancelButtonText: 'Batal',
                customClass: {
                    confirmButton: 'btn btn-success',
                    cancelButton: 'btn btn-danger ml-1',
                }
            })
            .then(async res => {
                if(res.value) {
                    const payload = {
                        fungsi: 1,
                        id: item.id
                    }
  
                    try {
                        this.loading = true
                        await this.$store.dispatch('pembelian/save', [payload])
                        this.loading = false
                        this.displaySuccess({
                            message: 'Data pembelian berhasil dihapus'
                        })
                        this.getPembelian()
                    }
                    catch(e) {
                        this.loading = false
                        this.displayError(e)
                        return false
                    }
                }
            })
        },
        add() {
            this.$router.push(`/pembelian/add`)
        },
        async getPembelian() {
            const params = {
              order: 'desc'
            }
            const pembelians = await this.$store.dispatch('pembelian/getData', params)
  
            // filter pembelian dengan jumlah pembayaran yg belum lunas
            pembelians.map(pembelian => {
              if(pembelian.informasi) {
                const belumLunas = pembelian.informasi.total_bayar < pembelian.informasi.total_pembelian
                if(belumLunas) {
                  this.pembelians.push(pembelian)
                }
              }
            })
            this.totalRows = this.pembelians.length
        },
        async getStatusApprove() {
            const menu = await this.currentMenu(this.$route.path)
            const params = {
                level_id: this.user.level.id
            }
            if(menu) {
                params.menu_id = menu.id
            }
            const listStatus = await this.$store.dispatch('statusrole/getData', params)
  
            // data_status => 1 approve gm, data_status => 2 pay finance
            const hasPermissionApprove = listStatus.some(status => status.data_status == 1)
            
            this.hasPermissionApprove = hasPermissionApprove
        },
        detail(item) {
            this.$router.push(`/pembelian/detail/${item.id}`)
        }
    },
    computed: {
      totalUtang() {
          return this.pembelians.reduce((total, item) => total += item.informasi.total_pembelian, 0)
      }
    },
    created() {
      // await this.getStatusApprove()
        this.getPembelian()
    }
  }
  </script>
  